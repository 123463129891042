jQuery(document).ready(function($){


// $('.mudar-cor').on("click", function(){
// 	$('.titulo').css('color', 'grey');
// });

	$('.slider-slide').slick({
		dots: true,
		appendDots: $('.slides-dots'),
		appendArrows: $('.slides-setas'),
		prevArrow: '<div class="slides-seta-esquerda"></div>',
		nextArrow: '<div class="slides-seta-direita"></div>'

	});



	$('.outros-produtos').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: true,
		appendDots: $('.slides-produtos-dots'),
		appendArrows: $('.slides-produtos-setas'),
		prevArrow: '<div class="slides-produtos-seta-esquerda"></div>',
		nextArrow: '<div class="slides-produtos-seta-direita"></div>',
		responsive: [
			{
				breakpoint:1120,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});


/*##############################################################################*/
/*###################  POPOVER MERCADOS  #######################################*/
/*##############################################################################*/


$(function () {
  $('[data-bs-toggle="popover"]').popover({
  	html: true,
  	customClass: 'pop'
  })
});




$('.mercado').each(function(){

	aidi = $(this).attr('aidi');

	nome_mercado = $(this).attr('nome-mercado');
	nome_ficheiro = $(this).attr('nome-ficheiro');
	img_src = '<img src="gestao/uploads/mercados/'+nome_ficheiro+'">';
	
	
	titulo = img_src+ ' ' +'<span>'+nome_mercado+'</span>';
	//titulo += '<h3 class="popover-h3">'+nome_mercado+'</h3>';

	//nome_mercado = img_src + nome_mercado;

	$('.'+aidi).each(function(){



		$('#'+aidi).css('fill', '#a5c61d');
		$('#'+aidi).addClass('countryPopover');
	 	$('#'+aidi).attr('data-bs-trigger', "focus");
		$('#'+aidi).attr('data-bs-toggle', "popover");
		$('#'+aidi).attr('title', titulo);

		
		
		classes = aidi+'produtos';

		conteudo = '<ul>';

		$('.'+classes).each(function(){

			conteudo += '<li>'+$(this).html()+'</li>';

			console.log($(this).html());
		});

		conteudo += '</ul>';

		$('#'+aidi).attr('data-bs-content', conteudo);

	});
	

});

/*##############################################################################*/
/*#################  FIM POPOVER MERCADOS  #####################################*/
/*##############################################################################*/


/*##############################################################################*/
/*###################  FORMULARIO  #############################################*/
/*##############################################################################*/

$('#form-contacto').submit(function(event){
	event.preventDefault();
	var nome = $("#nome").val();
	var email = $("#email").val();
	var assunto = $("#assunto").val();
	var mensagem = $("#mensagem").val();
	var enviar_formulario = $("#enviar-formulario").val();

	$('.form-message').load('includes/formulario.php',{
		nome:nome,
		email:email,
		assunto:assunto,
		mensagem:mensagem,
		enviar_formulario:enviar_formulario
	});

});



/*##############################################################################*/
/*#################  FIM FORMULARIO  ###########################################*/
/*##############################################################################*/



});  //document ready


/*##############################################################################*/
/*####################  GOOGLE MAPS  ###########################################*/
/*##############################################################################*/

function initMap(){
    var location = {lat: 39.371652, lng: -9.118814};
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 17,
        center:location,
        scrollwheel: false,
    });
        
        
    const contentInfoEscritorio = `<div class="info-window">
        								<h1 class="Nome">Santos e Pereira, Lda.</h1>
        								<h2 class="local">Escritório</h2>
        								<div class="info-window-content">
        									<p>Travessa da Paraventa, nº8</p>
        									<p>2510-704 Gaeiras</p>
        									<p>Portugal</p>
        								</div>
        							</div>
        						`;

    const contentInfoArmazem = `<div class="info-window">
        								<h1 class="Nome">Santos e Pereira, Lda.</h1>
        								<h2 class="local">Armazém</h2>
        							</div>
        						`;
  

    const infoEscritorio = new google.maps.InfoWindow({
    	content: contentInfoEscritorio,
  	});

  	const infoArmazem = new google.maps.InfoWindow({
    	content: contentInfoArmazem,
  	});


	const image = "img/icon/logo_mapa40.png";



	const icons = {
		escritorio: {
			icon: image,
		},
		armazem: {
			icon: image,
		}
	};

	const features = [
		{
			position: new google.maps.LatLng(39.371230, -9.118863),
			type: 'escritorio',
			infowindow : infoEscritorio,
		},
		{
			position: new google.maps.LatLng(39.371102, -9.118038),
			type: 'armazem',
			infowindow : infoArmazem,
		},

	];

 	// Create markers.
	for (let i = 0; i < features.length; i++) {

	    const marker = new google.maps.Marker({
	      position: features[i].position,
	      icon: icons[features[i].type].icon,
	      map: map,
	  	});

    	marker.addListener("click", () => {
    	
    		features[i].infowindow.open(map, marker);
  		});
  	}

}

/*##############################################################################*/
/*####################  FIM GOOGLE MAPS  #######################################*/
/*##############################################################################*/

